import { Box, InputLabel, Typography } from "@mui/material";
import { ReactNode } from "react";
import { numberFormatter } from "../../utils/Formatter/Global";

interface Props {
  label: ReactNode;
  value?: string | number;
  helperText?: ReactNode;
  endAdornment?: string;
  uomHelperText?: boolean;
}

const LabelInput = ({
  label,
  value,
  endAdornment,
  helperText,
  uomHelperText,
}: Props) => {
  const formatValue = value
    ? typeof value === "number"
      ? numberFormatter(value)
      : value
    : undefined;
  return (
    <Box>
      <InputLabel sx={{ fontSize: 11.11 }}>{label}</InputLabel>
      {endAdornment ? (
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
            {formatValue || "-"}
          </Typography>
          <InputLabel>{endAdornment}</InputLabel>
        </Box>
      ) : (
        <>
          <Typography sx={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
            {formatValue || "-"}
          </Typography>
          {helperText && (
            <Typography
              variant={uomHelperText ? "caption" : undefined}
              color={uomHelperText ? "gray.light" : undefined}
            >
              {helperText}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default LabelInput;
