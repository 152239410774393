import { useState, useEffect, Fragment, ReactNode } from "react";
import {
  TextField,
  IconButton,
  SxProps,
  TextFieldProps,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
// import GlobalService from "../../services/Global";
import { IDefaultForm, IOptionsCreatable } from "../../types/global";
import { createGraphQLClientWithMiddleware } from "../../services/graphqlClient";
import { GraphQLClient } from "graphql-request";
import {
  CreatableFieldDocumentType,
  CreatableFieldsQuery,
  useCreatableFieldCreateMutation,
  useCreatableFieldDeleteMutation,
  useCreatableFieldsQuery,
} from "../../generated/general";
import LabelInput from "../UI/LabelInput";

interface Props {
  control: IDefaultForm["control"];
  sx?: SxProps;
  name: string;
  dataName?: string;
  setValue: IDefaultForm["setValue"];
  error?: TextFieldProps["error"];
  defaultOptions: IOptionsCreatable[];
  onInputChange?: AutocompleteProps<any, any, any, any, any>["onInputChange"];
  onKeyDown?: TextFieldProps["onKeyDown"];
  onChange?: any;
  label?: string;
  helperText?: TextFieldProps["helperText"];
  placeholder?: TextFieldProps["placeholder"];
  disabled?: boolean;
  required?: boolean;
  documentType: string;
  endAdornment?: ReactNode;
  viewMode?: boolean;
  disableNewOptions?: boolean;
  keyId?: string | number;
}

const filter = createFilterOptions();

const ControlledSSCreatable = ({
  control,
  sx,
  name,
  dataName,
  setValue,
  error,
  defaultOptions,
  onInputChange,
  onKeyDown,
  onChange,
  helperText,
  label,
  placeholder,
  disabled,
  required,
  documentType,
  endAdornment,
  viewMode,
  disableNewOptions,
  keyId,
}: Props) => {
  // const [isInit, setIsInit] = useState(false);
  const [options, setOptions] = useState<IOptionsCreatable[]>([]);
  const [open, setOpen] = useState(false);

  const graphQLClientWithHeaderSetting: GraphQLClient =
    createGraphQLClientWithMiddleware("general");

  const { data, isSuccess } = useCreatableFieldsQuery<CreatableFieldsQuery>(
    graphQLClientWithHeaderSetting,
    {
      findManyInput: {
        document_type: documentType as CreatableFieldDocumentType,
        creatable_fields_name: dataName || name,
      },
    }
  );

  const { mutateAsync: createOption } = useCreatableFieldCreateMutation<Error>(
    graphQLClientWithHeaderSetting
  );

  const { mutateAsync: deleteOption } = useCreatableFieldDeleteMutation<Error>(
    graphQLClientWithHeaderSetting
  );

  useEffect(() => {
    if (isSuccess) {
      const getOptions = async () => {
        setOptions(
          data.CreatableFields?.map((option) => {
            return {
              id: option.id,
              value: option.name,
              label: option.name,
              isDeletable: true,
            };
          })
        );
      };
      getOptions();
    }
  }, [isSuccess, data]);

  const addOptionHandler = async (optionToAdd: any) => {
    const { CreatableFieldCreate } = await createOption({
      data: {
        document_type: documentType as CreatableFieldDocumentType,
        creatable_fields_name: dataName || name,
        name: optionToAdd,
      },
    });
    setValue(name, CreatableFieldCreate.name);
    setOptions((prevOptions: any) => [
      ...prevOptions,
      {
        id: CreatableFieldCreate.id,
        value: CreatableFieldCreate.name,
        label: CreatableFieldCreate.name,
        isDeletable: true,
      },
    ]);
  };

  const deleteOptionHandler = async (id: IOptionsCreatable["id"]) => {
    await deleteOption({
      uniqueInput: {
        id,
      },
    });
    setOptions((prevOptions) =>
      prevOptions.filter((option) => option.id !== id)
    );
    setValue(name, "");
  };

  const combinedOptions = [...defaultOptions, ...options].filter(
    (option, index, self) =>
      index === self.findIndex((o) => o.value === option.value)
  );

  return (
    <>
      <Controller
        key={`${name}-creatable${keyId ? `-${keyId}` : ""}`}
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {viewMode ? (
              <LabelInput label={label} value={field.value} />
            ) : (
              <Autocomplete
                key={`${name}-creatable-autocomplete${
                  keyId ? `-${keyId}` : ""
                }`}
                fullWidth
                {...field}
                onChange={
                  onChange
                    ? (e, option) => {
                        if (option?.label?.includes("เพิ่ม ")) {
                          addOptionHandler(option.inputValue);
                        }
                        onChange(e, option, field);
                      }
                    : (e, option) => {
                        if (option?.label?.includes("เพิ่ม ")) {
                          addOptionHandler(option.inputValue);
                        }
                        if (!option) {
                          return field.onChange("");
                        }
                        if (option.value) {
                          return field.onChange(option.value);
                        }
                        if (option.inputValue) {
                          return field.onChange(option.inputValue);
                        }
                        return field.onChange(option);
                      }
                }
                disabled={disabled}
                onInputChange={onInputChange}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) => inputValue === option.label
                  );
                  if (!disableNewOptions && inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      label: `เพิ่ม "${inputValue}"`,
                    });
                  }
                  return filtered;
                }}
                selectOnFocus
                onBlur={
                  disableNewOptions
                    ? (e: any) => {
                        return field.onChange(e.target?.value ?? "");
                      }
                    : undefined
                }
                clearOnBlur={!disableNewOptions}
                handleHomeEndKeys
                disableClearable={endAdornment ? true : false}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                options={combinedOptions}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.value || option.type;
                }}
                renderOption={(props, option) => {
                  return (
                    <li
                      {...props}
                      value={option.value}
                      key={`${name}-creatable-option-${
                        option.label || option.value
                      }-${keyId ?? "default"}`}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        wordBreak: "break-word",
                      }}
                    >
                      {option.label}
                      {!option?.label?.includes("เพิ่ม ") &&
                        option.isDeletable && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              deleteOptionHandler(option.id);
                              e.stopPropagation();
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                    </li>
                  );
                }}
                sx={sx}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={label}
                    size="small"
                    name={name}
                    error={error}
                    onKeyDown={onKeyDown}
                    helperText={helperText}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: endAdornment ?? (
                        <Fragment>
                          {params.InputProps.endAdornment}
                          {required && !label && (
                            <Typography mr={0.75} color="red">
                              *
                            </Typography>
                          )}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                readOnly={disabled}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export default ControlledSSCreatable;
