import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

interface Props {
  status?: "active" | "inactive" | string | null;
  text?: string;
  fontSize?: string;
}

const StyledStatus = styled(Box)<BoxProps>(({ bgcolor }) => ({
  "&": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "3px",
    backgroundColor: bgcolor,
    padding: ".25rem 0",
    minWidth: 95,
  },
}));

const StyledTypography = styled(Typography)<TypographyProps>(
  ({ color, fontSize }) => ({
    "&": {
      variant: "button",
      color: color,
      fontSize: fontSize ?? ".875rem",
    },
  })
);

const CustomizedStatus = ({ status, text, fontSize }: Props) => {
  const { t } = useTranslation();
  switch (status) {
    case "pre_sales":
    case "ร่าง":
    case "draft":
      return (
        <StyledStatus bgcolor="#E6E6E6">
          <StyledTypography color="#333333" fontSize={fontSize}>
            {text ?? t("status.draft")}
          </StyledTypography>
        </StyledStatus>
      );
    case "active":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {t("status.active")}
          </StyledTypography>
        </StyledStatus>
      );

    case "is_active":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            รอสแกน
          </StyledTypography>
        </StyledStatus>
      );
    case "in_transit":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            {t("status.in_transit")}
          </StyledTypography>
        </StyledStatus>
      );
    case "is_scan":
    case "is_scanned":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            สแกนแล้ว
          </StyledTypography>
        </StyledStatus>
      );
    case "post_sales":
    case "รอโอนย้าย":
    case "wait_transfer":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {text ?? t("status.wait_transfer")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_maintenance":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#E58000" fontSize={fontSize}>
            {text ?? t("status.wait_maintenance")}
          </StyledTypography>
        </StyledStatus>
      );
    case "success":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {text ?? t("status.success")}
          </StyledTypography>
        </StyledStatus>
      );
    case "failed":
      return (
        <StyledStatus bgcolor="#BDBDBD">
          <StyledTypography color="#FFFFFF" fontSize={fontSize}>
            {text ?? t("status.failed")}
          </StyledTypography>
        </StyledStatus>
      );
    case "failed_insurace_paid":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography color="#E41B1B" fontSize={fontSize}>
            {t("status.failed")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_receive":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            {text ?? t("status.wait_receive")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_issue":
      return (
        <StyledStatus bgcolor="#C4DBFD">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {text ?? t("status.wait_issue")}
          </StyledTypography>
        </StyledStatus>
      );
    case "เสร็จสิ้น":
    case "finished":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {text ?? t("status.finished")}
          </StyledTypography>
        </StyledStatus>
      );
    case "in_active":
    case "inactive":
      return (
        <StyledStatus bgcolor="gray.main">
          <StyledTypography color="gray.font" fontSize={fontSize}>
            {text ?? t("status.in_active")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ยกเลิก":
    case "cancelled":
    case "is_cancelled":
      return (
        <StyledStatus bgcolor="#D3D3D3">
          <StyledTypography color="#FFFFFF" fontSize={fontSize}>
            {text ?? t("status.cancelled")}
          </StyledTypography>
        </StyledStatus>
      );
    case "late":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography color="#E41B1B" fontSize={fontSize}>
            {t("status.late")}
          </StyledTypography>
        </StyledStatus>
      );
    case "support":
    case "รออนุมัติ":
    case "wait_approve":
      return (
        <StyledStatus bgcolor="#FCE69F">
          <StyledTypography color="#C3762E" fontSize={fontSize}>
            {text ?? t("status.wait_approve")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอตอบรับ":
    case "wait_accept":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#E58000" fontSize={fontSize}>
            {t("status.wait_accept")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ตอบรับแล้ว":
    case "accepted":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography color="#2167D3" fontSize={fontSize}>
            {t("status.accepted")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ไม่ตอบรับ":
    case "not_accepted":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography color="#E41B1B" fontSize={fontSize}>
            {"ไม่ตอบรับ"}
          </StyledTypography>
        </StyledStatus>
      );
    case "ไม่อนุมัติ":
    case "not_approved":
      return (
        <StyledStatus bgcolor="#FEECEB">
          <StyledTypography color="#B54839" fontSize={fontSize}>
            {t("status.not_approved")}
          </StyledTypography>
        </StyledStatus>
      );
    case "อนุมัติแล้ว":
    case "approved":
      return (
        <StyledStatus bgcolor="#E5F0FF">
          <StyledTypography color="#1F5BB2" fontSize={fontSize}>
            {t("status.approved")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_deliver":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            {text ?? t("status.wait_deliver")}
          </StyledTypography>
        </StyledStatus>
      );
    case "delivering":
      return (
        <StyledStatus bgcolor="#E8F3FD">
          <StyledTypography color="#2167D3" fontSize={fontSize}>
            {text ?? t("status.delivering")}
          </StyledTypography>
        </StyledStatus>
      );
    case "maintenancing":
      return (
        <StyledStatus bgcolor="#E8F3FD">
          <StyledTypography color="#2167D3" fontSize={fontSize}>
            {text ?? t("status.maintenancing")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอสั่งซื้อ":
    case "wait_ordered":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            {t("status.wait_ordered")}
          </StyledTypography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้วบางส่วน":
    case "partially_ordered":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527">
            {t("status.partially_ordered")}
          </StyledTypography>
        </StyledStatus>
      );
    case "สั่งซื้อแล้ว":
    case "fully_ordered":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {t("status.fully_ordered")}
          </StyledTypography>
        </StyledStatus>
      );
    case "นำเข้าแล้วบางส่วน":
    case "partially_imported":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {t("status.partially_imported")}
          </StyledTypography>
        </StyledStatus>
      );
    case "นำเข้าแล้ว":
    case "fully_imported":
      return (
        <StyledStatus bgcolor="#E5F8EF">
          <StyledTypography color="#246527" fontSize={fontSize}>
            {t("status.fully_imported")}
          </StyledTypography>
        </StyledStatus>
      );
    case "รอส่งคืน":
    case "wait_returned":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            {t("status.wait_returned")}
          </StyledTypography>
        </StyledStatus>
      );
    case "wait_payment":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#E58000" fontSize={fontSize}>
            {t("status.wait_payment")}
          </StyledTypography>
        </StyledStatus>
      );
    case "fully_payment":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {t("status.fully_payment")}
          </StyledTypography>
        </StyledStatus>
      );
    case "normal":
    case "ปกติ":
      return (
        <StyledStatus bgcolor="#E6F9F0">
          <StyledTypography color="#22915A" fontSize={fontSize}>
            {t("inventory.report.stock.normal")}
          </StyledTypography>
        </StyledStatus>
      );
    case "เกินสต็อก":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            {t("inventory.report.stock.exceed")}
          </StyledTypography>
        </StyledStatus>
      );
    case "ต้องสั่งซื้อ":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography color="#E41B1B" fontSize={fontSize}>
            {t("inventory.report.stock.need_purchase")}
          </StyledTypography>
        </StyledStatus>
      );
    case "approaching":
      return (
        <StyledStatus bgcolor="#FFF4E5">
          <StyledTypography color="#FB8C00" fontSize={fontSize}>
            {t("logistic.vehicle.approaching")}
          </StyledTypography>
        </StyledStatus>
      );
    case "overdue":
      return (
        <StyledStatus bgcolor="#FCE8E8">
          <StyledTypography color="#E41B1B" fontSize={fontSize}>
            {t("logistic.vehicle.overdue")}
          </StyledTypography>
        </StyledStatus>
      );
    case "none":
      return (
        <StyledStatus bgcolor="#F2F2F2">
          <StyledTypography color="#737373" fontSize={fontSize}>
            {t("logistic.vehicle.no_data")}
          </StyledTypography>
        </StyledStatus>
      );
    default:
      return null;
  }
};

export default CustomizedStatus;
